<template>
  <div>
    <unidades-sidebar
      :active.sync="isUnidadesSidebarActive"
      :user.sync="user"
      :unidades="unidades"
      :muda-unidade="mudaUnidade"
    />
    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
    >
      <template #button-content>
        <div class="d-sm-flex d-none user-nav">
          <p class="user-name font-weight-bolder mb-0">
            {{ userData.Name || userData.username }}
          </p>
          <span class="user-status">{{ userData.role }}</span>
        </div>
        <b-avatar
          size="40"
          :src="userData.avatar"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
        >
          <feather-icon
            v-if="!userData.Name"
            icon="UserIcon"
            size="22"
          />
        </b-avatar>
      </template>

      <template name="dropdown-items">
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout()"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="unidades && unidades.length > 1"
          link-class="d-flex align-items-center"
          @click="isUnidadesSidebarActive = true"
        >
          <feather-icon
            size="16"
            icon="MapIcon"
            class="mr-50"
          />
          <span>Unidades</span>
        </b-dropdown-item>
      </template>
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import { reactive, ref, toRefs } from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { $themeConfig } from '@themeConfig'
import { avatarText } from '@core/utils/filter'
import firebase from '@/libs/firebase'
import ClienteService from '@/service/ClienteService'
import UnidadesSidebar from './UnidadesSidebar.vue'

export default {
  components: { UnidadesSidebar },
  beforeCreate() {
    const user = JSON.parse(localStorage.getItem('userName'))
    if (user && user.nivel === 'MASTER') {
      this.$store.dispatch('cliente/findUnidades', user.clienteDocumento)
    }
    if (!user) {
      firebase.auth().signOut()
    }
  },
  methods: {
    logout() {
      firebase.auth().signOut()
    },
  },
  setup() {
    const appLoading = $themeConfig.app.appLoadingImage

    const currentUserStore = createNamespacedHelpers('currentUser')
    const clienteStore = createNamespacedHelpers('cliente')
    const state = reactive({
      user: currentUserStore.useGetters(['user']).user,
      unidades: clienteStore.useGetters(['unidades']).unidades,
      isUnidadesSidebarActive: false,
      unidadeAtual: {},
    })

    const userData = ref({ Name: state.user ? state.user.nome : '' })

    const mudaUnidade = (tenant, clienteId) => {
      state.isUnidadesSidebarActive = false
      appLoading.show()
      ClienteService.mudaUnidade(tenant, state.user.usuarioId, clienteId)
        .then(data => {
          if (data.success) {
            const cliente = data.body
            const dados = {
              ...state.user,
              clienteId: cliente.clienteId,
              urlLogo: cliente.urlLogo,
              unidade: cliente.nomeEmpresa,
              nomeFantasia: cliente.nomeFantasia,
              clienteDocumento: cliente.numeroDocumento,
            }
            window.localStorage.setItem('userName', JSON.stringify(dados))
            store.dispatch('currentUser/setCurrentUser', dados)
              .then(() => {
                store.dispatch('movimentacaoErro/findAllByClienteId', cliente.clienteId)
                  .then(() => {
                    appLoading.hide()
                    const { path } = router.currentRoute
                    if (path === '/') {
                      router.push('/envios/etiquetas').then(() => router.push(path))
                    } else {
                      router.push('/').then(() => router.push(path))
                    }
                  })
              })
          } else {
            appLoading.hide()
          }
        })
    }
    return { 
      ...toRefs(state),
      userData,
      avatarText,
      mudaUnidade,
    }
  },
}
</script>
