<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
    <div
      v-if="user.urlLogo"
      class="logo-container"
    >
      <img
        ref="img"
        :src="user.urlLogo"
        alt="logo"
        class="logo-img"
      >
    </div>
    <div v-else class="ml-2 text-white">
      <b>{{ user ? (user.nomeFantasia || user.unidade) : '' }}</b>
    </div>
    <!-- Left Col -->
    <b-navbar-nav class="nav align-items-center ml-auto mr-1">
      <img 
        v-if="checarTela()"
        src="../../../../assets/images/icons/log-icon.png"
        style="cursor: pointer;"
        alt="Logs"
        @click="irParaTelaLog()"
      >
      <dark-Toggler class="d-none d-lg-block" />
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { reactive, toRefs } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { BLink, BNavbarNav } from 'bootstrap-vue'
import DarkToggler from './components/DarkToggler.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    DarkToggler,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    irParaTelaLog() {
      this.$router.push({ name: 'logs', query: { from: this.$route.fullPath } })
    },
    checarTela() {
      const rota = this.$route.name
      switch (rota) {
        case 'gerar-romaneio': return true
        case 'layouts-importacao': return true
        case 'layouts-importacao-reversa': return true
        case 'endereco-reversa': return true
        default: return false
      }
    },
  },
  setup() {
    const currentUserStore = createNamespacedHelpers('currentUser')
    const state = reactive({
      user: currentUserStore.useGetters(['user']).user,
    })
    return {
      ...toRefs(state),
    }
  },
}
</script>
<style scoped>
.logo-img {
  max-height: 3.5em;
  max-width: 10em;
  border-radius: 6px;
}
.navbar-container {
  padding: 0;
}
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.5em;
  width: 11em;
  border-radius: 6px;
  background-color: #fff;
}
</style>
