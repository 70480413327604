<template>
  <b-sidebar
    id="unidades-sidebar"
    :visible="active"
    bg-variant="white"
    width="50%"
    shadow
    no-header
    right
    @change="(val) => $emit('update:active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Unidades
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <b-dropdown-item
        v-for="(unidade, index) in unidades"
        :key="index"
        link-class="d-flex align-items-center"
        :active="isUnidadeAtual(unidade)"
        @click="!isUnidadeAtual(unidade) ? mudaUnidade(unidade.tenant, unidade.clienteId) : null"
      >
        <feather-icon
          size="16"
          icon="UserIcon"
          class="mr-50"
        />
        <span>{{ unidade.nomeEmpresa }} - {{ unidade.nomeFantasia }} - {{ 
          unidade.numeroDocumento ? 
            (unidade.numeroDocumento.length === 11 ? 
              maskCpf(unidade.numeroDocumento) 
              : maskCnpj(unidade.numeroDocumento)) : '' }}</span>
      </b-dropdown-item>
    </template>
  </b-sidebar>
</template>

<script>
import utils from '@/helpers/utils'

export default {
  name: 'UnidadesSidebar',
  props: {
    active: { required: true, type: Boolean },
    user: { required: true, type: Object },
    unidades: { required: true, type: Array },
    mudaUnidade: { required: true, type: Function },
  },
  data() {
    const isUnidadeAtual = unidade => this.user.unidade === unidade.razaoSocial && this.user.clienteId === unidade.clienteId
    return {
      ...utils,
      isUnidadeAtual,
    }
  },
}
</script>

<style scoped>

</style>